import { useRef, useEffect, useContext } from "react";

import { Link } from "react-router-dom";

import { RegisterContext } from "contexts/RegisterContext";

import Box from "@mui/material/Box";

import QRCodeStyling from "qr-code-styling";

// Images
import dttdLogo from "assets/images/dttd-logo-qrcode.png";
import MKTypography from "components/MKTypography";

const qrCode = new QRCodeStyling({
  width: 344,
  height: 344,
  image: dttdLogo,
  dotsOptions: {
    color: "#000000",
    type: "rounded",
  },
  cornersSquareOptions: {
    type: "extra-rounded",
  },
  cornersDotOptions: {
    type: "dot",
  },
  imageOptions: {
    crossOrigin: "anonymous",
    margin: 10,
    hideBackgroundDots: true,
  },
});

// eslint-disable-next-line react/prop-types
function ShowQRCode() {
  const ref = useRef({});
  const registerContext = useContext(RegisterContext);

  useEffect(() => {
    qrCode.append(ref.current);
  }, []);

  useEffect(() => {
    //console.log("Generating QR code: ", registerContext.claimUrl);
    qrCode.update({
      data: registerContext.claimUrl,
    });
  }, [registerContext.claimUrl]);

  const boxStyle = {
    boxSizing: "border-box",
    position: "relative",
    width: "366px",
    height: "366px",
    margin: "auto",
    paddingTop: "10px",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), rgba(255, 255, 255, 0.3)",
    border: "1.89873px solid #FFFFFF",
    boxShadow: "0px 0px 35.1562px rgba(112, 252, 143, 0.8)",
    borderRadius: "16.4062px",
  };

  return (
    <Box sx={{ justifyContent: "center", alignItems: "center", pt: "20px" }}>
      <Box style={boxStyle}>
        <MKTypography component={Link} to={registerContext.claimUrl} target="_blank" align="center">
          <div ref={ref} />
        </MKTypography>
      </Box>
    </Box>
  );
}

export default ShowQRCode;

// Images
//import bgImage from "assets/images/double-ducks-front.png";
//import bgImageMobile from "assets/images/double-ducks-mobile.png";
import bgImage from "assets/images/bg-gradient.png";
import bgImageMobile from "assets/images/bg-front-gradient-mobile.png";

// Contest Background Responsive Style (CSS in JS)
export const contestBgStyle = {
  width: "100%",
  // background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${bgImage})`,
  //background: "linear-gradient(287.56deg, #F4FEFA 0%, rgba(244, 254, 250, 0) 100%)",
  //background: "rgba(255, 255, 255, 1)",
  background: `url(${bgImage})`,
  backgroundSize: "cover",
  backgroundPosition: "top",
  display: "grid",
  placeItems: "top",

  // Mobile: Landscape
  "@media only screen and (max-width: 390px)": {
    //background: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${bgImageMobile})`,
    //background: "rgba(255, 255, 255, 1)",
    background: `url(${bgImageMobile})`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    display: "grid",
    placeItems: "top",
    position: "sticky",
  },
};

import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

// @mui material components
//import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
//import Card from "@mui/material/Card";
//import Link from "@mui/material/Link";

// react-router components
import { Link } from "react-router-dom";

import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";
import ImageSwiper from "components/ImageSwiper";

import breakpoints from "assets/theme/base/breakpoints";

// Images
//import introDuck1 from "assets/images/intro_nft/intro_duck_1.png";
import imgNFTShowcase1 from "assets/images/intro_nft/showcase-img1.png";
import imgNFTShowcase2 from "assets/images/intro_nft/showcase-img2.png";
import imgNFTShowcase3 from "assets/images/intro_nft/showcase-img3.png";

// eslint-disable-next-line react/prop-types
function Introduction({ showCTA }) {
  const { t } = useTranslation();
  const [slideId, setSlideId] = useState("slide1");
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    // A function that sets the display state for Mobile view or not
    function displayMobileView() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileView);

    // Call the displayMobileView function to set the state with the initial value.
    displayMobileView();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileView);
  }, []);

  const slides = [
    { id: "slide1", image: imgNFTShowcase1, title: "Duckble the Joy, Duckble the Fun" },
    { id: "slide2", image: imgNFTShowcase2, title: "Bring a Friend" },
    { id: "slide3", image: imgNFTShowcase3, title: "Rewards Made for Two!" },
  ];

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      width={mobileView ? "100%" : "100%"}
    >
      <Grid
        item
        marginTop="16px"
        marginBottom="8px"
        display="flex"
        height="80px"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <MKTypography
          color="#0E1E17"
          align="bottom"
          sx={{
            fontWeight: "500",
            fontSize: "36px",
            lineHeight: 1.2,
          }}
        >
          <Trans
            i18nKey={`contest.introduction.title.${slideId}`}
            components={{
              br: <br />,
            }}
          />
        </MKTypography>
      </Grid>
      <Grid item container marginBottom="8px" justifyContent="center">
        <MKTypography
          variant="caption"
          color="#737C78"
          align="center"
          sx={{
            fontSize: "16px",
          }}
        >
          <Trans
            i18nKey={`contest.introduction.description.${slideId}`}
            components={{
              br: <br />,
            }}
          />
        </MKTypography>
      </Grid>
      <Grid item marginBottom="40px">
        <ImageSwiper
          slides={[...slides]}
          onSlideChanged={(id) => {
            setSlideId(id);
          }}
        ></ImageSwiper>
      </Grid>
      <Grid
        item
        marginBottom="24px"
        sx={{
          display: showCTA ? "block" : "none",
        }}
      >
        <MKButton variant="gradient" color="dttd" component={Link} to="/register">
          <MKTypography variant="button" fontWeight="bold">
            {t("contest.introduction.button")}
          </MKTypography>
        </MKButton>
      </Grid>
    </Grid>
  );
}

export default Introduction;

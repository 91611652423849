import { useState } from "react";
import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";

import "swiper/swiper.min.css";
import MKBox from "components/MKBox";

export class SwiperSlideImage {
  constructor(id, image, title) {
    this.id = id;
    this.image = image;
    this.title = title;
  }
}

let SwiperSlideImagePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

// eslint-disable-next-line react/prop-types
function ImageSwiper({ slides, onSlideChanged }) {
  const [currentIdx, setCurrentIdx] = useState(0);
  const pagination = {
    clickable: true,
    renderCustom: function (swiper, current, total) {
      return `<div class="swiper-pagination">${current} / ${total}</div>`;
    },
  };

  const boxStyle = {
    width: "350px",
    display: "block",
    "@media only screen and (max-width: 390px)": {
      width: "300px",
    },
  };

  const slideStyle = {
    position: "relative",
    margin: "auto",
    width: "350px",
    "@media only screen and (max-width: 390px)": {
      width: "300px",
    },
  };
  const imgStyle = {
    display: "block",
    position: "relative",
    width: "100%",
    height: "100%",
    margin: "auto",
    // top: "100%",
    // // top: "50%",
    // "-ms-transform": "translateY(0%)",
    // transform: "translateY(0%)",
  };

  const indicatorStyle = {
    display: "flex",
    justifyContent: "center",
    listStyle: "none",
    margin: "15px 0 0 0",
    padding: 0,
  };

  const indicatorItemStyle = {
    width: "88px",
    height: "4px",
    margin: "0 8px",
    borderRadius: "8px",
    background: "#F4FEFA",
    cursor: "pointer",
    "@media only screen and (max-width: 390px)": {
      width: "27px",
      borderRadius: "5px",
      margin: "0 4px",
    },
  };

  const indicatorItemActiveStyle = {
    width: "88px",
    height: "4px",
    margin: "0 8px",
    borderRadius: "8px",
    background: "linear-gradient(126.21deg, #03CAFC 6.23%, #70FC8F 100%)",
    cursor: "pointer",
    "@media only screen and (max-width: 390px)": {
      width: "27px",
      borderRadius: "5px",
      margin: "0 4px",
    },
  };

  return (
    <MKBox height="px" sx={boxStyle}>
      <Swiper
        modules={[Pagination, Navigation, Autoplay]}
        loop={true}
        pagination={pagination}
        slidesPerView={1}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        onRealIndexChange={(swiper) => {
          setCurrentIdx(swiper.realIndex);
          if (onSlideChanged) {
            onSlideChanged(slides[swiper.realIndex].id);
          }
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id} sx={slideStyle}>
            <MKBox
              sx={{
                position: "relative",
                width: "100%",
                height: "100%",
              }}
            >
              <img style={imgStyle} src={slide.image}></img>
            </MKBox>
          </SwiperSlide>
        ))}
      </Swiper>
      <MKBox>
        <ul style={indicatorStyle}>
          {slides.map((_item, idx) => (
            <li
              key={idx}
              style={idx === currentIdx ? indicatorItemActiveStyle : indicatorItemStyle}
            />
          ))}
        </ul>
      </MKBox>
    </MKBox>
  );
}

ImageSwiper.defalutProps = {
  slides: [],
};

ImageSwiper.propTypes = {
  slides: PropTypes.arrayOf(SwiperSlideImagePropType),
};

export default ImageSwiper;

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React
import { useState, useContext, useEffect, Fragment } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKTypography from "components/MKTypography";
//import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
//import DefaultNavbar from "dttd/Navbars/DefaultNavbar";
import DefaultFooter from "dttd/Footers/DefaultFooter";
//import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

import { MainContext } from "contexts/MainContext";
import { RegisterContext } from "contexts/RegisterContext";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Sections
import Introduction from "pages/Contest/sections/Introduction";
import RegisterFrom from "pages/ContestRegister/sections/RegisterForm";
import CompletedRegister from "pages/ContestRegister/sections/CompletedRegister";
//import CompletedOffers from "pages/ContestRegister/sections/CompletedOffers";

// Routes
//import routes from "routes";
// import routes from "routes-dttd";
import footerRoutes from "footer.routes";

import { registerBgStyle } from "./registerBgStyle";

// Images
//import bgImage from "assets/images/bg-campaign.png";
import logoImage from "assets/images/dttd-logo-for-bg.png";
//import logoArrImage from "assets/images/arr-logo.png";

// API calls
import { registerContest } from "pages/ContestRegister/logics/registerLogics";

// Event Track
import { log_event_analytics } from "dttd/functions/log_event_analytics";

function ContestRegister() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registerState, setRegisterState] = useState("register"); // States: register, filled, success, failed
  const [isProgress, setIsProgress] = useState(false);
  const [completedEmail, setCompletedEmail] = useState("");
  const [completedClaimUrl, setCompletedClaimUrl] = useState("");
  const [completedReferralCode, setCompletedReferralCode] = useState("");
  const mainContext = useContext(MainContext);
  const registerContext = useContext(RegisterContext);
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    // A function that sets the display state for Mobile view or not
    function displayMobileView() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileView);

    // Call the displayMobileView function to set the state with the initial value.
    displayMobileView();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileView);
  }, []);

  const onRegisterSuccess = (data) => {
    setIsProgress(false);
    //console.log("onRegisterSuccess: ", data);
    if (data !== undefined) {
      setCompletedClaimUrl(data.claim_url);
      setCompletedReferralCode(data.referral_code);
      registerContext.setClaimUrl(data.claim_url);
      registerContext.setReferralCode(data.referral_code);
      setRegisterState("success");
      log_event_analytics(mainContext, "register_success", {
        email: data.email,
        referral_code: data.referral_code,
      });
    }
  };

  const onRegisterError = (errCode, errMsg) => {
    setIsProgress(false);
    setRegisterState("failed");
    log_event_analytics(mainContext, "register_failed", { error_code: errCode, error_msg: errMsg });
    console.log("onRegisterError: ", errCode, errMsg);
    if (errCode === 2) {
      // Email already registered
      registerContext.setShowRegisterError(true);
      registerContext.setRegisterErrorMsg(t("contest.register.form.failed.alreadyregistered"));
    } else {
      registerContext.setShowRegisterError(true);
      registerContext.setRegisterErrorMsg(
        t("contest.register.form.failed.general") + ": " + errMsg
      );
    }
  };

  const handleRegisterState = (stateData) => {
    setRegisterState(stateData.state);
    //console.log("handleRegisterState: ", stateData);
    if (stateData.state === "filled") {
      setIsProgress(true);
      // TODO: send data to backend
      //console.log("sending data to backend");
      setCompletedEmail(stateData.data.email);
      setCompletedClaimUrl("");
      setCompletedReferralCode("");
      registerContest(stateData.data, onRegisterSuccess, onRegisterError);
      log_event_analytics(mainContext, "register", stateData.data);
    }
  };

  const logoImgStyle = {
    marginLeft: mobileView ? "20px" : "0px",
  };

  const navigateHome = () => {
    navigate("/");
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isProgress}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MKBox minHeight="746px" width="100%" sx={registerBgStyle}>
        <Container>
          <Grid container p="2px" spacing={0}>
            <Grid item xs={12}>
              <Card
                sx={{
                  m: 0,
                  p: 0,
                  backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                    rgba(black.main, 0.0),
                  boxShadow: "none",
                }}
              >
                <img
                  src={logoImage}
                  alt="DOTTED"
                  width="125"
                  height="40"
                  style={logoImgStyle}
                  onClick={navigateHome}
                />
              </Card>
            </Grid>
          </Grid>
          <Grid container p="0px" spacing={0}>
            {!mobileView && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  sx={{
                    display:
                      registerState === "register" || registerState === "failed" ? "block" : "none",
                  }}
                >
                  <Card
                    sx={{
                      my: 2,
                      p: 0,
                      backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                        rgba(black.main, 0.0),
                      boxShadow: "none",
                    }}
                  >
                    <Introduction showCTA={false} />
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={6}
                  sx={{
                    display:
                      registerState === "register" || registerState === "failed" ? "block" : "none",
                  }}
                >
                  <Card
                    sx={{
                      m: 0,
                      p: 0,
                      backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                        rgba(black.main, 0.0),
                      boxShadow: "none",
                    }}
                  >
                    <RegisterFrom onRegistrationStateChange={handleRegisterState} />
                  </Card>
                </Grid>
                <Grid item lg={12}>
                  <span>&nbsp;</span>
                </Grid>
              </Fragment>
            )}
            {mobileView && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                  sx={{
                    display:
                      registerState === "register" || registerState === "failed" ? "block" : "none",
                    my: 3,
                  }}
                >
                  <Card
                    sx={{
                      m: 0,
                      p: 0,
                      backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                        rgba(black.main, 0.0),
                      boxShadow: "none",
                    }}
                  >
                    <RegisterFrom onRegistrationStateChange={handleRegisterState} />
                  </Card>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                  sx={{
                    display:
                      registerState === "register" || registerState === "failed" ? "block" : "none",
                  }}
                >
                  <Card
                    sx={{
                      m: 0,
                      p: 0,
                      backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                        rgba(black.main, 0.0),
                      boxShadow: "none",
                      width: "100%",
                    }}
                  >
                    <Introduction showCTA={false} />
                  </Card>
                </Grid>
              </Fragment>
            )}
            {/* For Completed Registration */}
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              sx={{ display: registerState === "success" ? "block" : "none" }}
            >
              <Card
                sx={{
                  m: 0,
                  p: 0,
                  backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                    rgba(black.main, 0.0),
                  boxShadow: "none",
                }}
              >
                <CompletedRegister
                  email={completedEmail}
                  claim_url={completedClaimUrl}
                  referral_code={completedReferralCode}
                />
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <MKBox>
              <DefaultFooter content={footerRoutes} />
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default ContestRegister;

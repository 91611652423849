import { useState } from "react";
import { useTranslation } from "react-i18next";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

import CloseIcon from "@mui/icons-material/Close";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

// eslint-disable-next-line react/prop-types
function Offer({ image, title, description, routes }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const popupStyle = {
    position: "absolute",
    top: "47%",
    left: "50%",
    minHeight: "620px",
    transform: "translate(-50%, -50%)",
    width: "80%",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: "24",
    p: "4",
  };

  const buttonXStyle = {
    position: "absolute",
    top: "5px",
    right: "3px",
    color: "#344767",
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          minHeight: "350px",
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "20px",
        }}
      >
        <Grid container direction="row" justifyContent="center" alignItems="center" height={350}>
          <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
            <MKBox container height="105" alignItems="center">
              <img src={image} alt={title} width="auto" height="105" display="block" />
            </MKBox>
          </Grid>
          <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
            <MKTypography variant="h4" mb={1}>
              {t(title)}
            </MKTypography>
          </Grid>
          <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
            <MKTypography variant="body2" mb={1}>
              {t(description)}
            </MKTypography>
          </Grid>
          <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
            <MKButton variant="text" color="black" to={routes} onClick={handleOpen}>
              {t("offers.button")}
            </MKButton>
          </Grid>
        </Grid>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Card sx={popupStyle}>
              <MKButton variant="text" color="black" onClick={handleClose} sx={buttonXStyle}>
                <CloseIcon />
              </MKButton>
              <MKBox m={2}>
                <MKTypography id="transition-modal-title" variant="h6" component="h2">
                  {t(title)}
                </MKTypography>
                <MKTypography id="transition-modal-description" sx={{ mt: 2 }}>
                  {t(description)}
                </MKTypography>
              </MKBox>
            </Card>
          </Fade>
        </Modal>
      </Card>
    </>
  );
}

export default Offer;

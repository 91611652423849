import { IconButton, Snackbar } from "@mui/material";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useState, useContext } from "react";

import { MainContext } from "contexts/MainContext";

import colors from "assets/theme/base/colors";

import { useTranslation } from "react-i18next";

import { log_event_analytics } from "dttd/functions/log_event_analytics";

// eslint-disable-next-line react/prop-types
const CopyToClipboardButton = ({ data }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const mainContext = useContext(MainContext);
  const handleClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(data);
    log_event_analytics(mainContext, "copy_to_clipboard", { url: data });
  };

  return (
    <>
      <IconButton onClick={handleClick} color={colors.dttd.main}>
        <ContentCopyIcon color={colors.dttd.main} />
      </IconButton>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message={t("component.copytoclipboardbutton.message")}
      />
    </>
  );
};

export default CopyToClipboardButton;

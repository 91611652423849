/**
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import { MainProvider } from "contexts/MainContext";
import { RegisterProvider } from "contexts/RegisterContext";
import { Amplify, Analytics, AWSKinesisProvider } from "aws-amplify";
import awsExports from "./aws-exports";

import "./i18n";

Amplify.configure(awsExports);
Analytics.addPluggable(new AWSKinesisProvider());

Analytics.configure({
  AWSKinesis: {
    // OPTIONAL -  Amazon Kinesis service region
    region: awsExports.aws_project_region,
  },
});

const container = document.getElementById("root");

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
  <BrowserRouter>
    <MainProvider>
      <RegisterProvider>
        <App />
      </RegisterProvider>
    </MainProvider>
  </BrowserRouter>
);

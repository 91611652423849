/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext, useRef } from "react";

import { Trans, useTranslation } from "react-i18next";

import colors from "assets/theme/base/colors";
// import isValidEmail from "assets/theme/functions/isValidEmail";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";

// react-router components
import { Link } from "react-router-dom";

import HCaptcha from "@hcaptcha/react-hcaptcha";

// Context
import { MainContext } from "contexts/MainContext";
import { RegisterContext } from "contexts/RegisterContext";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import TcLink from "pages/ContestRegister/components/TcLink";
import PpLink from "pages/ContestRegister/components/PpLink";
import TrackedExtLink from "dttd/components/TrackedExtLink";
//import { setRef } from "@mui/material";

// Form validation
import { useForm } from "react-hook-form";
import registerContest from "../logics/registerLogics";

// eslint-disable-next-line react/prop-types
function RegisterForm({ onRegistrationStateChange }) {
  const { t } = useTranslation();
  const mainContext = useContext(MainContext);
  const registerContext = useContext(RegisterContext);
  const hCaptchaSiteKey =
    process.env.REACT_APP_HCAPTCHA_SITEKEY || "a7f3f468-c6be-4aab-b792-7fcba2adb286";
  const rewardSiteUrl = process.env.REACT_APP_REWARDS_URL || "http://localhost:3001";
  const [checked, setChecked] = useState(false);
  const handleChecked = () => setChecked(!checked);
  // // Form variables
  const {
    handleSubmit,
    formState: { errors },
    register,
    //control,
    reset,
  } = useForm();
  //const [email, setEmail] = useState("");
  //const [emailValid, setEmailValid] = useState(true);
  const [answer, setAnswer] = useState("");
  const [referral, setReferral] = useState("");
  const [token, setToken] = useState(null);
  const captchaRef = useRef(null);
  const [completedCaptcha, setCompletedCaptcha] = useState(false);

  const { refEmail, ...inputPropsEmail } = register("email", {
    required: t("contest.register.form.email.required"),
    pattern: {
      value: /\S+@\S+\.\S+/i,
      message: t("contest.register.form.email.invalid"),
    },
  });

  const { refAcknowledge, ...inputPropsAcknowledge } = register("acknowledge", {
    required: t("contest.register.form.acknowledge.required"),
    // checked: {
    //   value: true,
    //   message: t("contest.register.form.acknowledge.required"),
    // },
  });

  useEffect(() => {
    setReferral(mainContext.referrerCode);
  }, [mainContext.referrerCode]);

  useEffect(() => {
    console.log("State: Errors", errors);
  }, [errors]);

  useEffect(() => {
    registerContext.setShowRegisterError(false);
    registerContext.setRegisterErrorMsg("");
  }, [reset, errors.email]);

  const boldStyle = {
    fontWeight: "bold",
    color: colors.dttd.main,
  };

  // // function validateInputs() {
  // //   let valid = true;
  // //   if (!isValidEmail(email)) {
  // //     setEmailValid(false);
  // //   }
  // //   return valid;
  // // }

  // On state change for a successful hCaptcha verification
  useEffect(() => {
    if (token !== null) {
      setCompletedCaptcha(true);
    }
  }, [token]);

  const onSubmit = (data) => {
    if (completedCaptcha === true) {
      if (onRegistrationStateChange) {
        //captchaRef.current.execute();
        onRegistrationStateChange({
          state: "filled",
          data: {
            email: data.email,
            answer: answer,
            referral: referral,
          },
        });
      }
    }
  };

  const onExpire = () => {
    setCompletedCaptcha(false);
  };

  const onError = (err) => {
    console.log(`hCaptcha Error: ${err}`);
  };

  return (
    <Card
      sx={{
        width: "100%",
        minHeight: "612px",
        backgroundColor: "white",
        borderRadius: "20px",
        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
        px: 2,
        py: 2,
        mx: 0,
      }}
    >
      <FormGroup>
        <MKBox component="section" py={2}>
          <Container>
            <Grid
              container
              item
              justifyContent="center"
              xs={12}
              lg={12}
              mx="auto"
              textAlign="center"
            >
              <MKTypography
                mb={1}
                sx={{
                  color: "#0E1E17",
                  fontWeight: "500",
                  fontSize: "36px",
                }}
              >
                {t("contest.register.form.title")}
              </MKTypography>
            </Grid>
            <Grid
              container
              item
              justifyContent="center"
              xs={12}
              lg={12}
              mx="auto"
              textAlign="center"
            >
              <MKTypography
                mb={1}
                sx={{
                  color: "#737C78",
                  fontWeight: "500",
                  fontSize: "16px",
                }}
              >
                <Trans
                  i18nKey="contest.register.form.description"
                  components={{
                    italic: <i />,
                    bold: <span style={boldStyle} />,
                    action: (
                      <MKTypography
                        variant="body2"
                        mx={1}
                        component={TrackedExtLink}
                        to={rewardSiteUrl}
                        target="_blank"
                        fontWeight="bold"
                      />
                    ),
                  }}
                />
              </MKTypography>
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={12}
              sx={{
                mx: "auto",
                display: registerContext.showRegisterError ? "block" : "none",
              }}
            >
              <MKTypography variant="caption" fontWeight="bold" color="error">
                {registerContext.registerErrorMsg}
              </MKTypography>
            </Grid>
            <Grid container item xs={12} lg={12} sx={{ mx: "auto" }}>
              <MKBox width="100%" component="form" method="post">
                <MKBox>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        type="email"
                        variant="outlined"
                        label={t("contest.register.form.email")}
                        error={!!errors.email}
                        helperText={errors?.email?.message}
                        inputRef={refEmail}
                        {...inputPropsEmail}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        type="text"
                        label={t("contest.register.form.question")}
                        value={answer}
                        onChange={(e) => setAnswer(e.target.value)}
                        multiline
                        fullWidth
                        rows={4}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MKInput
                        type="text"
                        label={t("contest.register.form.referral")}
                        onChange={(e) => setReferral(e.target.value)}
                        value={referral}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} alignItems="center" ml={-1}>
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Switch inputRef={refAcknowledge} {...inputPropsAcknowledge} />
                        <MKTypography variant="caption" mb={1}>
                          <Trans
                            i18nKey="contest.register.form.acknowledge"
                            components={{ tclink: <TcLink />, pplink: <PpLink /> }}
                          />
                        </MKTypography>
                      </Stack>
                      <MKTypography
                        variant="caption"
                        ml={9}
                        color="error"
                        sx={{ display: !errors?.acknowledge?.message !== "" ? "block" : "none" }}
                      >
                        {errors?.acknowledge?.message}
                      </MKTypography>
                    </Grid>
                    <Grid item xs={12} sx={{ maxWidth: "280px" }}>
                      <div style={{ transform: "scale(0.88)", transformOrigin: "0 0" }}>
                        <HCaptcha
                          sitekey={hCaptchaSiteKey}
                          onVerify={setToken}
                          onError={onError}
                          onExpire={onExpire}
                          dataSize="compact"
                          ref={captchaRef}
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container item justifyContent="center" xs={12} mt="24px">
                    <MKButton
                      variant="gradient"
                      fullWidth
                      sx={{
                        color: colors.dttdDark.main,
                        background: `linear-gradient(126.21deg, ${colors.gradients.dttd.main} 6.23%, ${colors.gradients.dttd.state} 100%)`,
                        borderRadius: "400px",
                      }}
                      disabled={!completedCaptcha}
                      onClick={handleSubmit(onSubmit)}
                    >
                      <MKTypography variant="button" fontWeight="bold">
                        {t("contest.register.form.button")}
                      </MKTypography>
                    </MKButton>
                  </Grid>
                </MKBox>
              </MKBox>
            </Grid>
          </Container>
        </MKBox>
      </FormGroup>
    </Card>
  );
}

export default RegisterForm;

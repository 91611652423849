/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useContext, useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { useSearchParams } from "react-router-dom";

// Context
import { MainContext } from "contexts/MainContext";

// Material Kit 2 React components
import MKBox from "components/MKBox";
//import MKTypography from "components/MKTypography";
//import MKSocialButton from "components/MKSocialButton";

// Material Kit 2 React examples
//import DefaultNavbar from "dttd/Navbars/DefaultNavbar";
import DefaultFooter from "dttd/Footers/DefaultFooter";
//import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";

import { contestBgStyle } from "./contestBgStyle";

// Sections
import Introduction from "pages/Contest/sections/Introduction";
//import CTA from "pages/Contest/sections/CTA";

// Routes
//import routes from "routes";
// import routes from "routes-dttd";
import footerRoutes from "footer.routes";

// Images
//import bgImage from "assets/images/bg-campaign.png";
import logoImage from "assets/images/dttd-logo-for-bg.png";
//import logoArrImage from "assets/images/arr-logo.png";
import duckImage from "assets/images/double-ducks-mobile.png";
import duckMobileImage from "assets/images/double-ducks-mobile-slim.png";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

function Contest() {
  const mainContext = useContext(MainContext);
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [mobileView, setMobileView] = useState(false);

  const referralCode = searchParams.get("referral");
  if (referralCode) {
    //console.log("referralCode from Referrer", referralCode);
    mainContext.setReferrerCode(referralCode);
  } else {
    mainContext.setReferrerCode("");
  }

  useEffect(() => {
    // A function that sets the display state for Mobile view or not
    function displayMobileView() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileView);

    // Call the displayMobileView function to set the state with the initial value.
    displayMobileView();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileView);
  }, []);

  return (
    <>
      {/* Desktop View Layout */}
      {!mobileView && (
        <MKBox sx={contestBgStyle}>
          <Container>
            <Grid container p={2} spacing={2}>
              <Grid item xs={12}>
                <Card
                  sx={{
                    m: 0,
                    p: 0,
                    backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                      rgba(black.main, 0.0),
                    boxShadow: "none",
                  }}
                >
                  <img src={logoImage} alt="DOTTED" width="125" height="40" />
                </Card>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={6}
                lg={6}
                alignContent={"center"}
                justifyContent={"center"}
              >
                <Card
                  sx={{
                    m: 0,
                    p: 0,
                    backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                      rgba(black.main, 0.0),
                    boxShadow: "none",
                  }}
                >
                  <img src={duckImage} alt="Double Ducks" width="412" height="582" />
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={6} alignContent={"center"}>
                <Card
                  sx={{
                    m: 0,
                    p: 0,
                    backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                      rgba(black.main, 0.0),
                    boxShadow: "none",
                  }}
                >
                  <Introduction showCTA={true} />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <DefaultFooter content={footerRoutes} />
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      )}
      {/* Mobile View Layout */}
      {mobileView && (
        <MKBox sx={contestBgStyle}>
          <Container>
            <Grid container pt={4}>
              <Grid item xs={12} sm={12}>
                <Card
                  sx={{
                    m: 0,
                    p: 0,
                    backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                      rgba(black.main, 0.0),
                    boxShadow: "none",
                  }}
                >
                  <img src={logoImage} alt="DOTTED" width="88" height="28" />
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} alignContent={"center"} justifyContent={"center"}>
                <MKBox
                  alignContent={"center"}
                  width="100%"
                  sx={{
                    mt: 2,
                  }}
                >
                  <img src={duckMobileImage} alt="Double Ducks" width="100%" height="100%" />
                </MKBox>
              </Grid>
              <Grid item xs={12} sm={12} alignContent={"center"}>
                <Card
                  sx={{
                    mt: 2,
                    p: 0,
                    backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                      rgba(black.main, 0.0),
                    boxShadow: "none",
                  }}
                >
                  <Introduction showCTA={true} />
                </Card>
              </Grid>
              <Grid item xs={12}>
                <DefaultFooter content={footerRoutes} />
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      )}
      {/* <MKBox>
        <DefaultFooter content={footerRoutes} />
      </MKBox> */}
    </>
  );
}

export default Contest;

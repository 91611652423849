/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const register = /* GraphQL */ `
  mutation Register($input: AWSJSON) {
    register(input: $input)
  }
`;
export const createDttdArrRegistration = /* GraphQL */ `
  mutation CreateDttdArrRegistration(
    $input: CreateDttdArrRegistrationInput!
    $condition: ModelDttdArrRegistrationConditionInput
  ) {
    createDttdArrRegistration(input: $input, condition: $condition) {
      pk
      answer
      referrer_code
      acknowledged_terms
      claim_uuid_web
      claim_url_web
      claim_uuid_email
      claim_url_email
      referral_code
      dt_registered
      dt_updated
      createdAt
      updatedAt
    }
  }
`;
export const updateDttdArrRegistration = /* GraphQL */ `
  mutation UpdateDttdArrRegistration(
    $input: UpdateDttdArrRegistrationInput!
    $condition: ModelDttdArrRegistrationConditionInput
  ) {
    updateDttdArrRegistration(input: $input, condition: $condition) {
      pk
      answer
      referrer_code
      acknowledged_terms
      claim_uuid_web
      claim_url_web
      claim_uuid_email
      claim_url_email
      referral_code
      dt_registered
      dt_updated
      createdAt
      updatedAt
    }
  }
`;
export const deleteDttdArrRegistration = /* GraphQL */ `
  mutation DeleteDttdArrRegistration(
    $input: DeleteDttdArrRegistrationInput!
    $condition: ModelDttdArrRegistrationConditionInput
  ) {
    deleteDttdArrRegistration(input: $input, condition: $condition) {
      pk
      answer
      referrer_code
      acknowledged_terms
      claim_uuid_web
      claim_url_web
      claim_uuid_email
      claim_url_email
      referral_code
      dt_registered
      dt_updated
      createdAt
      updatedAt
    }
  }
`;

import { Trans, useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";

import MKTypography from "components/MKTypography";
import Offer from "../components/offer";

//import images
import offerTramLogo from "assets/images/offer_hk_tramways_logo.png";
import offerHotelLogo from "assets/images/offer_hotel_regala_logo.png";
import offerCheckLogo from "assets/images/offer_checkcheckcin_logo.png";

function Offers() {
  const { t } = useTranslation();

  const boldStyle = {
    fontWeight: "bold",
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid
          container
          item
          mb="8px"
          justifyContent="center"
          xs={12}
          lg={12}
          mx="auto"
          textAlign="center"
        >
          <MKTypography variant="h2" mb={1}>
            {t("offers.title")}
          </MKTypography>
        </Grid>
        <Grid container item justifyContent="center" xs={12} lg={12} mx="auto" textAlign="center">
          <MKTypography variant="b2" mb={1} color="black" align="center">
            <Trans
              i18nKey="offers.description"
              components={{
                bold: <span style={boldStyle} />,
              }}
            />
          </MKTypography>
        </Grid>
        <Grid container item>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid container item sx={4} lg={4} mx="auto">
              <Offer
                image={offerTramLogo}
                title="offers.tramways.title"
                description="offers.tramways.description"
                routes="/"
              />
            </Grid>
            <Grid container item sx={4} lg={4} mx="auto">
              <Offer
                image={offerHotelLogo}
                title="offers.hotel.title"
                description="offers.hotel.description"
                routes="/"
              />
            </Grid>
            <Grid container item sx={4} lg={4} mx="auto">
              <Offer
                image={offerCheckLogo}
                title="offers.checkcheck.title"
                description="offers.checkcheck.description"
                routes="/"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Offers;

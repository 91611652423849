import { default as keys } from "dttd/backend/keys";
import JSEncrypt from "jsencrypt";

function generate_token() {
  const domain = window.location.hostname;
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  const seconds = now.getSeconds().toString().padStart(2, "0");
  const hhmmss = `${hours}${minutes}${seconds}`;

  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(keys.public_key);

  const ts = now.getTime().toString();

  return jsEncrypt.encrypt(`${domain}|${hhmmss}|${ts}`);
}

export default generate_token;

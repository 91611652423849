/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext } from "react";

import { Trans, useTranslation } from "react-i18next";

import colors from "assets/theme/base/colors";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// react-router components
import { Link } from "react-router-dom";

import { MainContext } from "contexts/MainContext";
import { RegisterContext } from "contexts/RegisterContext";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

import ShowQRCode from "../components/ShowQRCode";
import CopyToClipboardButton from "dttd/components/CopyToClipboardButton";
import OfferImageSlider from "../components/OfferImageSlider";

import TrackedExtLink from "dttd/components/TrackedExtLink";

// Images
import continueDownImg from "assets/images/continue-down.png";
// import completedImg1 from "assets/images/completed-register-img1.png";
// import completedImg2 from "assets/images/completed-register-img2.png";
// import completedImg3 from "assets/images/completed-register-img3.png";
// import completedImg4 from "assets/images/completed-register-img4.png";
// import completedImg5 from "assets/images/completed-register-img5.png";
import completedImg1 from "assets/images/nft/double-ducks-nft-01.png";
import completedImg2 from "assets/images/nft/double-ducks-nft-02.png";
import completedImg3 from "assets/images/nft/double-ducks-nft-03.png";
import completedImg4 from "assets/images/nft/double-ducks-nft-04.png";
import completedImg5 from "assets/images/nft/double-ducks-nft-05.png";
import completedImg6 from "assets/images/nft/double-ducks-nft-06.png";

import rewardsOffer1 from "assets/images/rewards/rewards-staycation1.png";
import rewardsOffer2 from "assets/images/rewards/rewards-tramways.png";
import rewardsOffer3 from "assets/images/rewards/rewards-staycation2.png";
import rewardsOffer4 from "assets/images/rewards/rewards-staycation3.png";

// eslint-disable-next-line react/prop-types
function CompletedRegisterDesktop({ email, claim_url, referral_code }) {
  const { t } = useTranslation();
  const mainContext = useContext(MainContext);
  const registerContext = useContext(RegisterContext);
  const [referralLink, setReferralLink] = useState("");

  // eslint-disable-next-line no-undef
  const rewardUrl = process.env.REACT_APP_REWARDS_URL || "http://localhost:3001";
  const showRewards = process.env.REACT_APP_SHOW_BANNER == "1" || false;
  console.log(process.env.REACT_APP_SHOW_BANNER);

  useEffect(() => {
    setReferralLink(`${window.location.origin}/share?referral=${registerContext.referralCode}`);
  }, [registerContext.referralCode]);

  const boldStyle = {
    fontWeight: "bold",
    color: colors.dttd.main,
  };

  const borderStyle = {
    width: "90%",
    margin: "5px",
    borderRadius: "8px",
    border: "1px solid #03CAFC",
  };

  const nftImgStyle = {
    width: "130px",
    height: "130px",
  };

  const lineBreakStyle = {
    position: "absolute",
    width: "1px",
    height: "536px",
    left: "calc(50% - 1px/2 + 0.5px)",
    top: "49px",

    /* Text/Light#2 */
    background: "#DEDEDE",
  };

  const titleTextStyle = {
    fontWeight: "500",
    fontSize: "36px",
    lineHeight: 1.2,
    color: "#0E1E17",
  };

  const descriptionTextStyle = {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: 1.2,
    color: "#737C78",
  };

  const nftTitleTextStyle = {
    mt: "4px",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: 1.2,
    color: "#737C78",
  };

  const slides = [
    { id: "slide1", image: rewardsOffer1, title: "contest.register.completed.slide1.title" },
    { id: "slide2", image: rewardsOffer2, title: "contest.register.completed.slide2.title" },
    { id: "slide3", image: rewardsOffer3, title: "contest.register.completed.slide3.title" },
    { id: "slide4", image: rewardsOffer4, title: "contest.register.completed.slide4.title" },
  ];

  const nfts = [
    { id: "nft1", image: completedImg1, name: "The Big Buddha" },
    { id: "nft2", image: completedImg2, name: "Space Museum" },
    { id: "nft3", image: completedImg3, name: "Red Taxi" },
    { id: "nft4", image: completedImg4, name: "Bun Tower" },
    { id: "nft5", image: completedImg5, name: "Choi Hung" },
    { id: "nft6", image: completedImg6, name: "Pineapple Bun" },
  ];

  return (
    <Container>
      <Grid container item justifyContent="center" lg={12} mx="auto" textAlign="center">
        <Card
          lg={12}
          sx={{
            width: "100%",
            // minHeight: "609px",
            minHeight: "610px",
            maxHeight: "610px",
            backgroundColor: "white",
            borderRadius: "20px",
            padding: "2px",
          }}
        >
          <MKBox component="section" py={2}>
            <Container>
              <Grid container item justifyContent="center" lg={12} mx="auto" textAlign="center">
                <Grid item lg={6} px={1} mt={5} alignContent="center">
                  <MKTypography variant="h3" mb={1} sx={titleTextStyle}>
                    {t("contest.register.completed.title")}
                  </MKTypography>
                  <MKTypography variant="body2" mb={1} sx={descriptionTextStyle}>
                    <Trans
                      i18nKey="contest.register.completed.description"
                      components={{
                        bold: <span style={boldStyle} />,
                      }}
                    />
                  </MKTypography>
                  <MKBox>
                    <ShowQRCode />
                  </MKBox>
                  <MKBox pt={2}>
                    <MKTypography variant="caption" sx={descriptionTextStyle}>
                      <Trans
                        i18nKey="contest.register.completed.bottom"
                        components={{
                          bold: <span style={boldStyle} />,
                        }}
                      />
                    </MKTypography>
                  </MKBox>
                  {/* <MKBox mt={2} pt={1}>
                    <MKTypography
                      variant="button"
                      mb={1}
                      mt={2}
                      sx={{ textDecoration: "underline", color: "#4971FF" }}
                    >
                      <MKTypography
                        variant="button"
                        mb={1}
                        pt={1}
                        component={TrackedClaimLink}
                        to={registerContext.claimUrl}
                        target="_blank"
                        sx={{ color: "#4971FF" }}
                      >
                        {registerContext.claimUrl}
                      </MKTypography>
                    </MKTypography>
                  </MKBox> */}
                </Grid>
                <Grid item lg={6} px={1} mt={5} alignContent="center">
                  <Stack direction="column" spacing={1} alignContent="center">
                    <MKTypography variant="h3" mb={1} sx={titleTextStyle}>
                      {t("contest.register.completed.share.title")}
                    </MKTypography>
                    <MKTypography variant="body2" mb={1} sx={descriptionTextStyle}>
                      <Trans
                        i18nKey="contest.register.completed.share.description"
                        components={{
                          bold: <span style={boldStyle} />,
                          br: <br />,
                        }}
                      />
                    </MKTypography>
                    <Container>
                      <Grid
                        container
                        item
                        justifyContent="center"
                        mt="30px"
                        //mx="auto"
                        textAlign="center"
                        spacing={0}
                      >
                        <Grid item alignContent={"center"}>
                          <Stack direction="row">
                            {nfts.slice(0, 3).map((nft) => (
                              <Stack key={nft.id} direction="column">
                                <img
                                  src={nft.image}
                                  style={nftImgStyle}
                                  alt={t("contest.register.completed.share." + nft.id)}
                                />
                                <MKTypography sx={nftTitleTextStyle}>{nft.name}</MKTypography>
                              </Stack>
                            ))}
                          </Stack>
                        </Grid>
                        <Grid item alignContent={"center"}>
                          <Stack direction="row">
                            {nfts.slice(3, 6).map((nft) => (
                              <Stack key={nft.id} direction="column">
                                <img
                                  src={nft.image}
                                  style={nftImgStyle}
                                  alt={t("contest.register.completed.share." + nft.id)}
                                />
                                <MKTypography sx={nftTitleTextStyle}>{nft.name}</MKTypography>
                              </Stack>
                            ))}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Container>
                    <Container>
                      <Grid container item mt="30px" mx={1}>
                        <MKBox style={borderStyle}>
                          <Grid container item>
                            <Grid item xs={10} md={10} lg={10} justifyContent="flex-start">
                              <MKTypography variant="caption">{referralLink}</MKTypography>
                            </Grid>
                            <Grid item xs={1} md={1} lg={1} mr={"5px"}>
                              <CopyToClipboardButton data={referralLink} />
                            </Grid>
                          </Grid>
                        </MKBox>
                      </Grid>
                    </Container>
                  </Stack>
                </Grid>
              </Grid>
            </Container>
          </MKBox>
          <MKBox component="section" py={2} style={lineBreakStyle} />
        </Card>
        {showRewards && (
          <Grid container item justifyContent="center" lg={12} mx="auto" pt={1} textAlign="center">
            <Grid item lg={12} pb={1}>
              <MKTypography variant="caption" sx={{ fontWeight: "bold", color: "#737C78" }}>
                {t("contest.register.completed.browsenext")}
              </MKTypography>
            </Grid>
            <Grid item lg={12} pb={1}>
              <img src={continueDownImg} width="36px" height="36px" alt="continue" />
            </Grid>
            <Grid item lg={12} pb={2}>
              <MKBox sx={{ minHeight: "500px" }}>
                <Grid container justifyContent="center" alignItems="center" textAlign="center">
                  <Grid item lg={6}>
                    {/* <img src={staycationOffer} width="565px" height="471" alt="staycation" /> */}
                    <OfferImageSlider slides={[...slides]}></OfferImageSlider>
                  </Grid>
                  <Grid item lg={6}>
                    <Grid
                      container
                      item
                      justifyContent="center"
                      textAlign="center"
                      sx={{ display: "flex", flexDirection: "column", height: "100%" }}
                    >
                      <Grid item lg={12}>
                        <Stack direction="column" spacing={1} justifyContent="center">
                          <MKTypography variant="h4" mb={1} sx={titleTextStyle}>
                            {t("contest.register.completed.rewards.title")}
                          </MKTypography>
                          <MKTypography variant="caption" mb={2} sx={descriptionTextStyle}>
                            {t("contest.register.completed.rewards.description")}
                          </MKTypography>
                          <MKButton
                            variant="gradient"
                            sx={{
                              mx: "auto",
                              color: colors.dttdDark.main,
                              display: "relative",
                              left: "23%",
                              width: "304px",
                              height: "48px",
                              background: `linear-gradient(126.21deg, ${colors.gradients.dttd.main} 6.23%, ${colors.gradients.dttd.state} 100%)`,
                              borderRadius: "400px",
                              top: "24px",
                            }}
                          >
                            <MKTypography
                              variant="button"
                              component={TrackedExtLink}
                              to={`${rewardUrl}/?sessionid=${mainContext.sessionId}`}
                              target="_blank"
                              fontWeight="bold"
                            >
                              {t("contest.register.completed.rewards.button")}
                            </MKTypography>
                          </MKButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </MKBox>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default CompletedRegisterDesktop;

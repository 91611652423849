/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext, Fragment } from "react";

import { Trans, useTranslation } from "react-i18next";

import colors from "assets/theme/base/colors";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// react-router components
import { Link } from "react-router-dom";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

import CompletedRegisterMobile from "./CompletedRegisterMobile";
import CompletedRegisterDesktop from "./CompletedRegisterDesktop";

// eslint-disable-next-line react/prop-types
function CompletedRegister({ email, claim_url, referral_code }) {
  const { t } = useTranslation();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    // A function that sets the display state for Mobile view or not
    function displayMobileView() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
      } else {
        setMobileView(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileView);

    // Call the displayMobileView function to set the state with the initial value.
    displayMobileView();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileView);
  }, []);

  return (
    <Container>
      <Grid
        container
        item
        justifyContent="center"
        xs={12}
        lg={12}
        mx="auto"
        textAlign="center"
        spacing={0}
      >
        <Fragment>
          {mobileView && (
            <CompletedRegisterMobile
              email={email}
              claim_url={claim_url}
              referral_code={referral_code}
            />
          )}
          {!mobileView && (
            <CompletedRegisterDesktop
              email={email}
              claim_url={claim_url}
              referral_code={referral_code}
            />
          )}
        </Fragment>
      </Grid>
    </Container>
  );
}

export default CompletedRegister;

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import DefaultFooter from "dttd/Footers/DefaultFooter";
import footerRoutes from "footer.routes";

import Offers from "pages/MerchantOffers/sections/Offers";
import { offersBgStyle } from "./offersBgStyle";

// Import Images
import logoImage from "assets/images/dttd-logo-for-bg.png";

function MerchantOffers() {
  return (
    <>
      <MKBox sx={offersBgStyle}>
        <Container>
          <Grid container p={2} spacing={2}>
            <Grid item xs={12}>
              <Card
                sx={{
                  m: 0,
                  p: 0,
                  backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                    rgba(black.main, 0.0),
                  boxShadow: "none",
                }}
              >
                <img src={logoImage} alt="DOTTED" width="125" height="40" />
              </Card>
            </Grid>
            <Grid item xs={12} md={12} lg={12} alignContent={"center"}>
              <Card
                sx={{
                  m: 0,
                  p: 0,
                  backgroundColor: ({ palette: { black }, functions: { rgba } }) =>
                    rgba(black.main, 0.0),
                  boxShadow: "none",
                }}
              >
                <Offers />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <MKBox>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default MerchantOffers;

import { API } from "aws-amplify";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api";
import { register } from "graphql/mutations";
import generate_token from "dttd/functions/generate_token";

export const registerContest = async (data, success, err) => {
  try {
    const apiRequest = await API.graphql({
      query: register,
      variables: { input: JSON.stringify(data) },
      authMode: GRAPHQL_AUTH_MODE.AWS_LAMBDA,
      authToken: generate_token(),
    });
    //console.log("apiRequest: ", apiRequest);
    const response = JSON.parse(apiRequest.data.register);
    const response_body = response.body;
    /*
    Response body
    {
      "success": False,
      "message": "Failed to register user",
      "data": {
        "claim_url": "",
        "referral_code": "",
      },
      "error_code": 0,
      "error_message": "",
    }
    */
    if (response.statusCode === 200) {
      if (response_body.success) {
        success(response_body.data);
      } else {
        err(response_body.error_code, response_body.error_message);
      }
    } else {
      err(400, `[registerContest] Error in register: ${response.statusCode}`);
    }
  } catch (error) {
    console.log("[registerContest] Error in API call: ", error);
    if (err) {
      err(400, error.toString());
    }
  }
};

export default registerContest;

import { Analytics } from "aws-amplify";

function addHours(date, hours) {
  // 👇 Make copy with "Date" constructor.
  const dateCopy = new Date(date);
  dateCopy.setHours(dateCopy.getHours() + hours);
  return dateCopy;
}

export const log_event_analytics = (mainContext, event_name, attributes) => {
  try {
    if (mainContext.sessionId !== "") {
      // eslint-disable-next-line no-undef
      const env = process.env.REACT_APP_BUILD_ENV || "dev";
      const d_local = new Date();
      const d_utc = new Date(
        Date.UTC(
          d_local.getUTCFullYear(),
          d_local.getUTCMonth(),
          d_local.getUTCDate(),
          d_local.getUTCHours(),
          d_local.getUTCMinutes(),
          d_local.getUTCSeconds()
        )
      );
      const d_rpt = addHours(d_utc, 8);
      const log_data = {
        session_id: mainContext.sessionId,
        user_agent: mainContext.userAgent,
        log_datetime: d_utc.toISOString(),
        local_datetime: d_rpt.toISOString(),
        is_mobile: mainContext.isMobile,
        brower_name: mainContext.browserName,
        user_ip_version: mainContext.userIpVersion,
        user_ip: mainContext.userIp,
        user_country_code: mainContext.userCountryCode,
        user_country_name: mainContext.userCountryName,
        user_city: mainContext.userCity,
        user_timezone: mainContext.userTimezone,
        user_latitude: mainContext.userLatitude,
        user_longitude: mainContext.userLongitude,
        language: mainContext.language,
        referrer_code: mainContext.referrerCode,
        event_name: event_name,
        attributes: JSON.stringify(attributes),
        year: d_rpt.getUTCFullYear().toString().padStart(2, "0"),
        month: (d_rpt.getUTCMonth() + 1).toString().padStart(2, "0"),
        day: d_rpt.getUTCDate().toString().padStart(2, "0"),
        hour: d_rpt.getUTCHours().toString().padStart(2, "0"),
      };
      Analytics.record(
        {
          data: log_data,
          partitionKey: "session_id",
          // eslint-disable-next-line no-undef
          streamName: `dttdarrcampaignKinesis-${env}`,
        },
        "AWSKinesis"
      );
      // console.log(
      //   "log_event_analytics to stream",
      //   log_data,
      //   // eslint-disable-next-line no-undef
      //   `dttdarrcampaignKinesis-${env}`
      // );
    }
    // else {
    //   console.log("log_event_analytics: no session id -> skip");
    // }
  } catch (error) {
    console.error("Error in log_event_analytics: ", error);
  }
};

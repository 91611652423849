/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================


=========================================================

*/
// react-router components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Images
import bgImage from "assets/images/campaign-rotating-card-back.png";

function Information() {
  const { t } = useTranslation();
  return (
    <MKBox
      minHeight="75vh"
      width="100%"
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.6),
            rgba(gradients.dark.state, 0.6)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
            {t("campaign.register.description")}
          </MKTypography>
          <MKButton
            color="default"
            sx={{ color: ({ palette: { dark } }) => dark.main }}
            component={Link}
            to="/campaign/register"
          >
            {t("campaign.register.button")}
          </MKButton>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;

/* eslint-disable no-unused-vars */
import { useState, useEffect, useContext } from "react";

import { Trans, useTranslation } from "react-i18next";

import colors from "assets/theme/base/colors";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

// react-router components
import { Link } from "react-router-dom";

import { MainContext } from "contexts/MainContext";
import { RegisterContext } from "contexts/RegisterContext";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

import TrackedClaimLink from "dttd/components/TrackedClaimLink";
import TrackedExtLink from "dttd/components/TrackedExtLink";
import OfferImageSlider from "../components/OfferImageSlider";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

import ShowQRCode from "../components/ShowQRCode";
import CopyToClipboardButton from "dttd/components/CopyToClipboardButton";
import installAppImg from "assets/images/dttd-install-app.png";

// Images
import continueDownImg from "assets/images/continue-down.png";
import completedImg1 from "assets/images/nft/double-ducks-nft-01.png";
import completedImg2 from "assets/images/nft/double-ducks-nft-02.png";
import completedImg3 from "assets/images/nft/double-ducks-nft-03.png";
import completedImg4 from "assets/images/nft/double-ducks-nft-04.png";
import completedImg5 from "assets/images/nft/double-ducks-nft-05.png";
import completedImg6 from "assets/images/nft/double-ducks-nft-06.png";

import rewardsOffer1 from "assets/images/rewards/rewards-staycation1.png";
import rewardsOffer2 from "assets/images/rewards/rewards-tramways.png";
import rewardsOffer3 from "assets/images/rewards/rewards-staycation2.png";
import rewardsOffer4 from "assets/images/rewards/rewards-staycation3.png";

// Giveaway
import { GiveawayList } from "./GiveawayClaims";
import GiveawayDetail from "./GiveawayDetail";

// eslint-disable-next-line react/prop-types
function CompletedRegisterMobile({ email, claim_url, referral_code }) {
  const { t } = useTranslation();
  const mainContext = useContext(MainContext);
  const registerContext = useContext(RegisterContext);
  const [referralLink, setReferralLink] = useState("");

  // eslint-disable-next-line no-undef
  const rewardUrl = process.env.REACT_APP_REWARDS_URL || "http://localhost:3001";
  // eslint-disable-next-line no-undef
  const showRewards = process.env.REACT_APP_SHOW_BANNER == "1" || false;

  useEffect(() => {
    setReferralLink(`${window.location.origin}/share?referral=${registerContext.referralCode}`);
  }, [registerContext.referralCode]);

  const boldStyle = {
    fontWeight: "bold",
    color: colors.dttd.main,
  };

  const borderStyle = {
    width: "90%",
    margin: "5px",
    borderRadius: "8px",
    border: "1px solid #03CAFC",
  };

  const nftImgStyle = {
    width: "93px",
    height: "93px",
  };

  const slides = [
    { id: "slide1", image: rewardsOffer1, title: "contest.register.completed.slide1.title" },
    { id: "slide2", image: rewardsOffer2, title: "contest.register.completed.slide2.title" },
    { id: "slide3", image: rewardsOffer3, title: "contest.register.completed.slide3.title" },
    { id: "slide4", image: rewardsOffer4, title: "contest.register.completed.slide4.title" },
  ];

  return (
    <Container>
      <Grid container item justifyContent="center" xs={12} mx="auto" mt={5} textAlign="center">
        <Card
          sx={{
            width: "100%",
            minHeight: "612px",
            backgroundColor: "white",
            borderRadius: "20px",
            padding: "2px",
          }}
        >
          <MKTypography variant="h5" mt={1} pt={1} mb={1}>
            {t("contest.register.completed.title")}
          </MKTypography>
          <MKTypography variant="body2" mb={1}>
            <Trans
              i18nKey="contest.register.completed.description"
              components={{
                bold: <span style={boldStyle} />,
                br: <br />,
              }}
            />
          </MKTypography>
          <MKBox>
            {/* <ShowQRCode /> */}
            <img src={installAppImg} width="275px" height="128px" alt="install DOTTED app" />
          </MKBox>
          <Grid container item justifyContent="center" xs={12} my={2}>
            <MKButton
              variant="gradient"
              target="_blank"
              component={TrackedClaimLink}
              to={registerContext.claimUrl}
              sx={{
                mx: "10px",
                color: colors.dttdDark.main,
                width: "90%",
                height: "48px",
                background: `linear-gradient(126.21deg, ${colors.gradients.dttd.main} 6.23%, ${colors.gradients.dttd.state} 100%)`,
                borderRadius: "400px",
              }}
            >
              <MKTypography variant="button" fontWeight="bold">
                {t("contest.register.completed.claim_btn")}
              </MKTypography>
            </MKButton>
          </Grid>
          <Divider sx={{ mx: "8px", opacity: "0.8" }} variant="middle" />
          <MKTypography variant="h5" mt={2} mb={1}>
            {t("contest.register.completed.share.title")}
          </MKTypography>
          <MKTypography variant="body2" mb={1}>
            <Trans
              i18nKey="contest.register.completed.share.description"
              components={{
                bold: <span style={boldStyle} />,
                br: <br />,
              }}
            />
          </MKTypography>
          <Container>
            <Grid
              container
              item
              justifyContent="center"
              mt="30px"
              //mx="auto"
              textAlign="center"
            >
              <Grid item alignContent={"center"}>
                <Stack direction="row">
                  <img
                    src={completedImg1}
                    style={nftImgStyle}
                    alt={t("contest.register.completed.share.nft1")}
                  />
                  <img
                    src={completedImg2}
                    style={nftImgStyle}
                    alt={t("contest.register.completed.share.nft2")}
                  />
                  <img
                    src={completedImg3}
                    style={nftImgStyle}
                    alt={t("contest.register.completed.share.nft3")}
                  />
                </Stack>
              </Grid>
              <Grid item alignContent={"center"}>
                <Stack direction="row">
                  <img
                    src={completedImg4}
                    style={nftImgStyle}
                    alt={t("contest.register.completed.share.nft4")}
                  />
                  <img
                    src={completedImg5}
                    style={nftImgStyle}
                    alt={t("contest.register.completed.share.nft5")}
                  />
                  <img
                    src={completedImg6}
                    style={nftImgStyle}
                    alt={t("contest.register.completed.share.nft6")}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Container>
          <Container>
            <Grid container item justifyContent="center" mt="30px" mx={1}>
              <MKBox style={borderStyle}>
                <Grid container item justifyContent="flex">
                  <Grid item xs={10} md={10} lg={10} justifyContent="flex">
                    <MKTypography variant="caption">{referralLink}</MKTypography>
                  </Grid>
                  <Grid item xs={1} md={1} lg={1} mr={"5px"}>
                    <CopyToClipboardButton data={referralLink} />
                  </Grid>
                </Grid>
              </MKBox>
            </Grid>
          </Container>

          <Divider sx={{ mx: "8px", opacity: "0.8" }} variant="middle" />
          <MKTypography variant="h5" mt={2} mb={2}>
            {t("contest.register.completed.redeem.title")}
          </MKTypography>
          {GiveawayList.map((g, index) => {
            return (
              <Grid item xs={12} md={12} lg={12} mx={1} key={`giveaway-${index}`}>
                <Stack direction="column" spacing={1}>
                  <GiveawayDetail idx={index} giveaway={g} />
                </Stack>
              </Grid>
            );
          })}
          <MKTypography variant="h5">
            <span>&nbsp;</span>
          </MKTypography>

          {/* <MKBox mt={2} pt={1}>
            <MKTypography
              variant="button"
              mb={1}
              mt={2}
              sx={{ textDecoration: "underline", color: "#4971FF" }}
            >
              <MKTypography
                variant="button"
                mb={1}
                pt={1}
                component={TrackedClaimLink}
                to={registerContext.claimUrl}
                target="_blank"
                sx={{ color: "#4971FF" }}
              >
                {registerContext.claimUrl}
              </MKTypography>
            </MKTypography>
          </MKBox> */}
        </Card>
      </Grid>
      {showRewards && (
        <Grid container item justifyContent="center" xs={12} mx="auto" pt={1} textAlign="center">
          <Grid item xs={12} pb={1}>
            <MKTypography variant="caption" sx={{ fontWeight: "bold" }}>
              {t("contest.register.completed.browsenext")}
            </MKTypography>
          </Grid>
          <Grid item xs={12} pb={1}>
            <img src={continueDownImg} width="24px" height="24px" alt="continue" />
          </Grid>
          <Grid item xs={12} pb={3}>
            <MKBox sx={{ minHeight: "325px" }}>
              <Grid container justifyContent="center" alignItems="center" textAlign="center">
                <Grid item xs={12}>
                  <OfferImageSlider slides={[...slides]}></OfferImageSlider>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    item
                    justifyContent="center"
                    textAlign="center"
                    sx={{ display: "flex", flexDirection: "column", height: "100%" }}
                  >
                    <Grid item xs={12}>
                      <Stack direction="column" spacing={1} justifyContent="center">
                        <MKTypography variant="h4" mb={1}>
                          {t("contest.register.completed.rewards.title")}
                        </MKTypography>
                        <MKTypography variant="caption" mb={1}>
                          {t("contest.register.completed.rewards.description")}
                        </MKTypography>
                        <MKButton
                          variant="gradient"
                          sx={{
                            mx: "auto",
                            color: colors.dttdDark.main,
                            display: "relative",
                            left: "5%",
                            width: "295px",
                            height: "47px",
                            background: `linear-gradient(126.21deg, ${colors.gradients.dttd.main} 6.23%, ${colors.gradients.dttd.state} 100%)`,
                            borderRadius: "400px",
                          }}
                        >
                          <MKTypography
                            variant="button"
                            component={TrackedExtLink}
                            to={`${rewardUrl}/?sessionid=${mainContext.sessionId}`}
                            target="_blank"
                            fontWeight="bold"
                          >
                            {t("contest.register.completed.rewards.button")}
                          </MKTypography>
                        </MKButton>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default CompletedRegisterMobile;

// Images
import bgImage from "assets/images/bg-gradient-register.png";

// Contest Register Background Responsive Style (CSS in JS)
export const registerBgStyle = {
  width: "100%",
  background: `url(${bgImage})`,
  backgroundSize: "cover",
  backgroundPosition: "top",
  display: "grid",
  placeItems: "top",
  paddingTop: "16px",

  // Mobile: Landscape
  "@media only screen and (max-width: 390px)": {
    background: "rgba(255, 255, 255, 1)",
    backgroundSize: "cover",
    backgroundPosition: "top",
    display: "grid",
    placeItems: "top",
    position: "sticky",
    paddingTop: "12px",
  },
};

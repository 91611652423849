import React from "react";

export const RegisterContext = React.createContext({
  claimUrl: "",
  setClaimUrl: () => {},
  referralCode: "",
  setReferralCode: () => {},
  showRegisterError: false,
  setShowRegisterError: () => {},
  registerErrorMsg: "",
  setRegisterErrorMsg: () => {},
});

// eslint-disable-next-line react/prop-types
export const RegisterProvider = ({ children }) => {
  const [claimUrl, setClaimUrl] = React.useState("");
  const [referralCode, setReferralCode] = React.useState("");
  const [showRegisterError, setShowRegisterError] = React.useState(false);
  const [registerErrorMsg, setRegisterErrorMsg] = React.useState("");

  return (
    <RegisterContext.Provider
      value={{
        claimUrl,
        setClaimUrl,
        referralCode,
        setReferralCode,
        showRegisterError,
        setShowRegisterError,
        registerErrorMsg,
        setRegisterErrorMsg,
      }}
    >
      {children}
    </RegisterContext.Provider>
  );
};

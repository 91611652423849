import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import { useTranslation } from "react-i18next";

import "swiper/swiper.min.css";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

export class SwiperSlideImage {
  constructor(id, image, title) {
    this.id = id;
    this.image = image;
    this.title = title;
  }
}

let SwiperSlideImagePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
});

// eslint-disable-next-line react/prop-types
function ImageSwiper({ slides }) {
  const { t } = useTranslation();

  const pagination = {
    clickable: true,
    renderCustom: function (swiper, current, total) {
      return `<div class="swiper-pagination">${current} / ${total}</div>`;
    },
  };

  const boxStyle = {
    width: "565px",
    display: "block",
    "@media only screen and (max-width: 768px)": {
      width: "350px",
    },
  };

  const slideStyle = {
    position: "relative",
    margin: "auto",
    width: "350px",
    "@media only screen and (max-width: 390px)": {
      width: "335px",
    },
  };
  const imgStyle = {
    margin: "20px 20px 0",
    width: "90%",
    position: "relative",
    top: "100%",
    // // top: "50%",
    // "-ms-transform": "translateY(0%)",
    // transform: "translateY(0%)",
  };

  return (
    <MKBox height="px" sx={boxStyle}>
      <Swiper
        modules={[Pagination, Navigation, Autoplay]}
        loop={true}
        pagination={pagination}
        slidesPerView={1}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
      >
        {slides.map((slide) => (
          <SwiperSlide key={slide.id} sx={slideStyle}>
            <MKBox
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(255, 255, 255, 1)",
                borderRadius: "16px",
                padding: "0 0 20px",
              }}
            >
              <img style={imgStyle} src={slide.image}></img>
              <MKTypography variant="body2" color="dttdFooterText">
                {t(slide.title)}
              </MKTypography>
            </MKBox>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <MKBox>
        <ul style={indicatorStyle}>
          {slides.map((_item, idx) => (
            <li
              key={idx}
              style={idx === currentIdx ? indicatorItemActiveStyle : indicatorItemStyle}
            />
          ))}
        </ul>
      </MKBox> */}
    </MKBox>
  );
}

ImageSwiper.defalutProps = {
  slides: [],
};

ImageSwiper.propTypes = {
  slides: PropTypes.arrayOf(SwiperSlideImagePropType),
};

export default ImageSwiper;

// Images
//import regalHotelLogo from "assets/images/giveaways/regal_hotel.jpg";
import regalRiversideLogo from "assets/images/giveaways/regal_riverside_logo.png";
import regalHongkongLogo from "assets/images/giveaways/regal_hongkong_logo.png";
import regalAirportLogo from "assets/images/giveaways/regal_airport_logo.png";
import chartCoffeeLogo from "assets/images/giveaways/chart-coffee.png";
import cookieismLogo from "assets/images/giveaways/cookieism.png";

export const GiveawayList = [
  {
    //Chart Coffee - Buy 1 Get 1 Coffee
    logo: chartCoffeeLogo,
    logoAlt: "giveaway.chart_coffee.alt",
    title: "giveaway.chart_coffee.title",
    tracking: "Chart Coffee - Buy 1 Get 1 Coffee",
    claimUrl: "https://hop.dttd.app/1WNvFpGSDAb",
  },
  {
    //Cookieism - Buy 1 Get 1 Free
    logo: cookieismLogo,
    logoAlt: "giveaway.cookieism.alt",
    title: "giveaway.cookieism.title",
    tracking: "Cookieism - Buy 1 Get 1 Free",
    claimUrl: "https://hop.dttd.app/dbxVPBHSDAb",
  },
  {
    logo: regalAirportLogo,
    logoAlt: "giveaway.regal_airport_lunch_b1g1.alt",
    title: "giveaway.regal_airport_lunch_b1g1.title",
    tracking: "Regal Airport Aficionado Lunch B1G1",
    claimUrl: "https://hop.dttd.app/uiTEz9gFEAb",
  },
  {
    logo: regalHongkongLogo,
    logoAlt: "giveaway.regal_hk_lunch_b1g1.alt",
    title: "giveaway.regal_hk_lunch_b1g1.title",
    tracking: "Regal Hongkong Rivoli Lunch B1G1",
    claimUrl: "https://hop.dttd.app/sX6YnaxFEAb",
  },
  // {
  //   logo: regalHotelLogo,
  //   logoAlt: "giveaway.regal_patisserie.alt",
  //   title: "giveaway.regal_patisserie.title",
  //   tracking: "Regal Patisserie 20% off",
  //   claimUrl: "https://hop.dttd.app/IMkGoYrACAb",
  // },
  {
    logo: regalRiversideLogo,
    logoAlt: "giveaway.regal_riverside_court.alt",
    title: "giveaway.regal_riverside_court.title",
    tracking: "Regal Riverside Regal Court 20% off",
    claimUrl: "https://hop.dttd.app/cY4acopUDAb",
  },
  {
    logo: regalRiversideLogo,
    logoAlt: "giveaway.regal_riverside_terrace.alt",
    title: "giveaway.regal_riverside_terrace.title",
    tracking: "Regal Riverside Regal Terrace 20% off",
    claimUrl: "https://hop.dttd.app/NTA2y9zACAb",
  },
  {
    //Regal Hongkong Hotel  Regal Palace 20% off E-coupon
    logo: regalHongkongLogo,
    logoAlt: "giveaway.regal_hongkong_palace.alt",
    title: "giveaway.regal_hongkong_palace.title",
    tracking: "Regal Hongkong Regal Palace 20% off",
    claimUrl: "https://hop.dttd.app/Lv6WrRuUDAb",
  },
];

/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================


=========================================================

*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { useTranslation } from "react-i18next";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/campaign-rotating-card-front.png";
import bgBack from "assets/images/campaign-rotating-card-back.png";

function Information() {
  const { t } = useTranslation();
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={t("campaign.info.frontcard.title")}
                description={t("campaign.info.frontcard.description")}
              />
              <RotatingCardBack
                image={bgBack}
                title={t("campaign.info.backcard.title")}
                description={t("campaign.info.backcard.description")}
                // action={{
                //   type: "internal",
                //   route: "/sections/page-sections/page-headers",
                //   label: "start with header",
                // }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="emoji_events"
                  title={t("campaign.info.cards.reward.title")}
                  description={t("campaign.info.cards.reward.description")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="grade"
                  title={t("campaign.info.cards.journey.title")}
                  description={t("campaign.info.cards.journey.description")}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="psychology"
                  title={t("campaign.info.cards.ai.title")}
                  description={t("campaign.info.cards.ai.description")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="share"
                  title={t("campaign.info.cards.share.title")}
                  description={t("campaign.info.cards.share.description")}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
